/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Toggle Navigation
        function toggleNavBar() {
          $('.menu-nav').slideToggle(400);
        }
        $('#menu-toggle').click( toggleNavBar );

        // Form Select
        $("select").each(function() {
          $(this).wrapAll("<div class='select-wrapper'></div>");
        });

        // Responsive Tables
        $('table').addClass('table');

        $(".table").each(function(){
            $(this).wrapAll("<div class='table-wrapper'></div>");
        });

        $("table.table").click(function(){
          $(".table-wrapper").toggleClass( $(this).attr("class") );
        });

        // Responsive Videos
        // $(".map-wrapper iframe").each(function(){
        //   $(this).wrapAll("<div class='video-container'></div>");
        // });

        $(".gform_button").each(function(){
          $(this).wrapAll("<div class='submit-wrapper'></div>");
        });

        $('.gform_wrapper').find('.submit-wrapper').append("<i class='ico ico-key'></i>");



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        function positionHeader() {
          $('.hero .home-beers-wrapper').css({
            '-webkit-transform': 'translateY(-' + ($(window).scrollTop() * 0.5) + 'px)',
            '-moz-transform': 'translateY(' + $(window).scrollTop() + 'px)',
            '-ms-transform': 'translateY(' + $(window).scrollTop() + 'px)',
            '-o-transform': 'translateY(' + $(window).scrollTop() + 'px)',
            'transform': 'translateY(-' + ($(window).scrollTop() * 0.5) + 'px)'
          });

          $('.hero .content-area').css({
            '-webkit-transform': 'translateY(-' + ($(window).scrollTop() * 0.25) + 'px)',
            '-moz-transform': 'translateY(' + $(window).scrollTop() + 'px)',
            '-ms-transform': 'translateY(' + $(window).scrollTop() + 'px)',
            '-o-transform': 'translateY(' + $(window).scrollTop() + 'px)',
            'transform': 'translateY(-' + ($(window).scrollTop() * 0.25) + 'px)'
          });
        }
        $(window).scroll( positionHeader );
        positionHeader();

        // This function checks if the screen is resized in an efficient way.
        //If resized it will call "on_resize" function.
        function on_resize(c,t){onresize=function(){clearTimeout(t);t=setTimeout(c,100);};return c;}

        // --- Hover Effect --- //
        on_resize(function() {
          var my_article,
              my_headline,
              my_article_height,
              my_headline_height,
              my_hover_position;

          $(".event .event-item").hover(
            //Hover In
            function(){
              my_article = $(this);
              my_headline = my_article.find('header');
              my_article_height = my_article.outerHeight(); // OuterHeight = Get The Height Including The Padding
              my_headline_height = my_headline.outerHeight();
              my_hover_position = my_article_height - my_headline_height;

              my_headline.css("bottom", my_hover_position);
            },
            // Hover Out
            function(){
              my_headline.css("bottom", 0);
            }
          );
        })();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Bar & Restaurant Page
    'bar_restaurant': {
      init: function() {

        // Docks interior nav to left when scrolling

        var restaurantMenuPos = $('.restaurant-wrapper').position();

        function travelingNav() {
          $('body').toggleClass('traveling', $(window).scrollTop() > restaurantMenuPos.top );
        }
        $(window).scroll(travelingNav);
        travelingNav();

        // Smooth Scrolling Plugin
        var defaultDuration = 500;
        var edgeOffset = 100;
        var myDiv = document.getElementById("restaurant-items");
        var myScroller = zenscroll.createScroller(myDiv, defaultDuration, edgeOffset);
        myScroller.center(target);

        //var current = $('.restaurant-wrapper .restaurant-items h1');



        //$('.restaurant-wrapper .restaurant-nav ul li a').addClass('test');
        // var navItem = $('.restaurant-wrapper .restaurant-nav ul li a[href="#"' + $('.restaurant-wrapper .restaurant-items h1').attr('id') + '"]');
        // navItem.addClass('test');

      }
    },
    // Contest Anniversary Page
    'contest-anniversary': {
      init: function() {
        $( "<p>Test</p>" ).appendTo( ".hero" );
      }
    },
    // Bar & Restaurant Page
    'brewery': {
      init: function() {

        // Prevent Zooming on google map while using mouse scroll
        $('.map-wrapper')
          .click(function(){
              $(this).find('iframe').addClass('clicked');
          })
          .mouseleave(function(){
              $(this).find('iframe').removeClass('clicked');
        });
      }
    },
    // Bar & Restaurant Page
    'events': {
      init: function() {

        function filterEvents(){
          var eventDate, eventLocation, eventType;
          eventDate = $('#event-date').val();
          eventType = $('#event-type').val();

          eventDateSelector = (eventDate === "all") ? '' : "[data-date='"+eventDate+"']";
          eventTypeSelector = (eventType === "all") ? '' : "[data-type='"+eventType+"']";

          $('.event-landing-events .event').hide();

          $(".event-landing-events .event"+eventDateSelector+eventTypeSelector).show();
        }

        $('#event-date').on('change', function(){
          filterEvents();
        });

        $('#event-location').on('change', function(){
          window.location = '/events/?loc='+$(this).val();
        });

        $('#event-type').on('change', function(){
          filterEvents();
        });




        // This function checks if the screen is resized in an efficient way.
        //If resized it will call "on_resize" function.
        function on_resize(c,t){onresize=function(){clearTimeout(t);t=setTimeout(c,100);};return c;}

        // --- Hover Effect --- //
        on_resize(function() {
          var my_article,
              my_headline,
              my_article_height,
              my_headline_height,
              my_hover_position;

          $(".event .event-item").hover(
            //Hover In
            function(){
              my_article = $(this);
              my_headline = my_article.find('header');
              my_article_height = my_article.outerHeight(); // OuterHeight = Get The Height Including The Padding
              my_headline_height = my_headline.outerHeight();
              my_hover_position = my_article_height - my_headline_height;

              my_headline.css("bottom", my_hover_position);
            },
            // Hover Out
            function(){
              my_headline.css("bottom", 0);
            }
          );
        })();

      }
    },
    // Single Event Page
    'single_event': {
      init: function() {

        // Prevent Zooming on google map while using mouse scroll
        $('.map-wrapper')
          .click(function(){
              $(this).find('iframe').addClass('clicked');
          })
          .mouseleave(function(){
              $(this).find('iframe').removeClass('clicked');
        });

        // This function checks if the screen is resized in an efficient way.
        //If resized it will call "on_resize" function.
        function on_resize(c,t){onresize=function(){clearTimeout(t);t=setTimeout(c,100);};return c;}

        // --- Hover Effect --- //
        on_resize(function() {
          var my_article,
              my_headline,
              my_article_height,
              my_headline_height,
              my_hover_position;

          $(".event .event-item").hover(
            //Hover In
            function(){
              my_article = $(this);
              my_headline = my_article.find('header');
              my_article_height = my_article.outerHeight(); // OuterHeight = Get The Height Including The Padding
              my_headline_height = my_headline.outerHeight();
              my_hover_position = my_article_height - my_headline_height;

              my_headline.css("bottom", my_hover_position);
            },
            // Hover Out
            function(){
              my_headline.css("bottom", 0);
            }
          );
        })();

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
